<template>
  <v-container>
    <v-snackbar v-model="snackbar.show" top right color="success" timeout="2000">
      <v-icon class="mr-2">mdi-check</v-icon>
      <span> Company Created! </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <h2 >Create you own company</h2>
    <p class="text-dark">Manage your own company from a fully-functional and easy-to-use platform. Enter your business information below and get access to a powerful productivity software that your business needs. </p>
        <v-row>
          <v-col cols="12" lg="7" md="7">
            <v-card flat outlined>
                <v-card-text class="border-bottom black--text">
                    <p>Please select the type of company to create. </p>
                    <v-row dense>
                      <v-col cols="12" :md="!status.admin ? '12' : '6' ">
                        <v-btn @click="type('owner')" large color="#0A009B"  block text :class=" status.type == 'owner' ? 'v-btn--active ' : ' ' + ' rounded font-weight-bold '" outlined   >
                           Parent Company
                          </v-btn>
                 
                      </v-col>
                      <v-col cols="12" md="6" v-if="status.admin">
                         <v-btn @click="type('affiliated') "  large color="#0A009B" block text :class=" status.type == 'affiliated' ? 'v-btn--active ' : ' ' + ' rounded font-weight-bold '"  outlined  >
                           Subsidiary
                          </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                <v-form @submit.prevent="checkDuplicate" ref="formOwner" lazy-validation v-if="status.type =='owner' " enctype="multipart/form-data"   >
                  <v-card-text>
                      <v-row dense>
                        <v-col cols="12" class="rounded d-relative">
                          <p class="mb-1">Company Logo</p>
                          <div class="w-100 ">
                            <v-img v-if="display.parent_image" :src="display.parent_image " contain height="150" class="border rounded"> </v-img>
                            <v-img v-else src='@/assets/default.svg' height="150" class="border rounded"> </v-img>
                            <v-btn  :disabled="disabled.owner" icon absolute bottom right   color="#0A009B" class="bg-white mb-2" elevation="4" @click="uploadLogo('parent')"> 
                              <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Company Name"
                            :rules="rules.name"
                            required
                            v-model="form.owner.company.name"
                            :disabled="disabled.owner"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Company Email"
                            required
                            :rules="rules.email"
                            v-model="form.owner.company.company_email"
                            :disabled="disabled.owner"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="4" md="4">
                         <!-- <v-select :disabled="disabled.owner" label="Country" v-model="form.owner.company.country" :items="arrays.countries" :rules="rules.country"> </v-select> -->
                         <Countries  v-bind:disabled="disabled.owner" v-model="form.owner.company.country" v-bind:country="form.owner.company.country"> </Countries>
                        </v-col>
                        <v-col cols="12" lg="8" md="8">
                          <v-text-field
                            label="Company Address"
                            required
                            :rules="rules.address"
                            v-model="form.owner.company.address"
                            :disabled="disabled.owner"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Company Contact Number"
                            v-model="form.owner.company.contact_number"
                            @keyup="accept"
                            required
                            :rules="rules.contact"
                            :disabled="disabled.owner"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <timezone v-model="form.owner.company.timezone_id"> </timezone>
                        </v-col>
                        <v-col cols="12">
                          <v-select 
                          :loading="loadings.products" 
                          v-model="form.owner.product_id" 
                          :items="arrays.products" 
                          item-value="id" 
                          item-text="name"
                          label="Select Product to Avail"
                          :rules="rules.product"
                          :disabled="disabled.owner"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    <div v-if="arrays.errors.length > 0">
                      <small v-for="error in arrays.errors" :key="error.id"  class="text-white py-1 px-2 border bg-danger rounded mr-1">
                        {{ error }} </small>
                    </div>
                  </v-card-text>
                  <v-card-actions class="border-top ">
                    <div class="d-block text-right w-100">
                      <v-btn text @click="close" :disabled="disabled.cancel"  class="text-secondary"> Cancel </v-btn>
                      <v-btn type="submit" text color="#0A009B" :loading="loadings.btn_create" > Create </v-btn>
                    </div>
                  </v-card-actions>
                   <input ref="parentUploader" class="d-none" type="file" accept="image/png, image/jpeg, image/bmp" @change="imageUpload('parent')" />
                </v-form>
                <v-form @submit.prevent="checkDuplicate"  ref="formAffiliated" lazy-validation v-if="status.type =='affiliated' " enctype="multipart/form-data" >
                <v-card-text>
                    <v-row dense>
                       <v-col cols="12" class="rounded d-relative">
                         <p class="mb-1">Company Logo</p>
                          <div class="w-100">
                            <v-img v-if="display.affiliated_image" :src="display.affiliated_image " contain height="150" class="border rounded"> </v-img>
                            <v-img v-else src='@/assets/default.svg' height="150" class="border rounded"> </v-img>
                            <v-btn :disabled="disabled.affiliated" icon absolute bottom right   color="#0A009B" class="bg-white mb-2" elevation="4" @click="uploadLogo('affiliated')"> 
                              <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Company Name"
                          :rules="rules.name"
                          required
                          v-model="form.affiliated.name"
                          :disabled="disabled.affiliated"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select 
                        :rules="rules.parent" 
                        label="Parent Company" 
                        :items="superCompanies" 
                        item-value="company_id" 
                        item-text="company.name" 
                        v-model="form.affiliated.parent_id"
                        :disabled="disabled.affiliated"
                        >
                        </v-select>
                      </v-col>
                      
                      <v-col cols="12">
                        <v-text-field
                          label="Company Email"
                          required
                          :rules="rules.email"
                          v-model="form.affiliated.company_email"
                          :disabled="disabled.affiliated"
                        ></v-text-field>
                      </v-col>
                       <v-col cols="12" lg="4" md="4">
                         <!-- <v-select :disabled="disabled.affiliated" label="Country" v-model="form.affiliated.country" :items="arrays.countries" :rules="rules.country">

                         </v-select> -->
                         <Countries  v-bind:disabled="disabled.affiliated" v-model="form.affiliated.country" v-bind:country="form.affiliated.country"> </Countries>
                        </v-col>
                        <v-col cols="12" lg="8" md="8">
                          <v-text-field
                            label="Company Address"
                            required
                            :rules="rules.address"
                            v-model="form.affiliated.address"
                            :disabled="disabled.affiliated"
                          ></v-text-field>
                        </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Company Contact Number"
                          v-model="form.affiliated.contact_number"
                          @keyup="accept"
                          required
                          :rules="rules.contact"
                          :disabled="disabled.affiliated"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <timezone v-model="form.affiliated.timezone_id"> </timezone>
                      </v-col>
                    </v-row>
                  <div v-if="arrays.errors.length > 0">
                    <small v-for="error in arrays.errors" :key="error.id"  class="text-white py-1 px-2 border bg-danger rounded mr-1">
                      {{ error }} </small>
                  </div>
                </v-card-text>
                <v-card-actions class="border-top ">
                  <div class="d-block text-right w-100">
                    <v-btn text @click="close" :disabled="disabled.cancel" class="text-secondary"> Close </v-btn>
                    <v-btn type="submit" text color="#0A009B" :loading="loadings.btn_create" > Save </v-btn>
                  </div>
                </v-card-actions>
                 <input ref="affiliatedUploader" class="d-none" type="file" accept="image/png, image/jpeg, image/bmp" @change="imageUpload('affiliated')" />
              </v-form>
            </v-card>
          </v-col>
          <v-col cols="12" lg="5" md="5">
            <v-card flat outlined>
              <div class="px-4 w-100 py-4" >
                    <v-img
                        height="200"
                        class="white--text"
                        src="@/assets/create.svg"
                      >
                    </v-img>
                  </div>
              <v-card-text>
                <p >Etousoft is committed to providing you business management solutions that will help your company focus, connect, and get things done. Whether you are a start-up, an entrepreneur, medium-sized or large enterprise, Etousoft ensures its applications suit your growing business needs. Even if you are in a remote world, Etousoft helps you in managing your team and building project plans.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-dialog v-model="dialog.show" width="400" persistent>
          <v-card >
            <v-toolbar color="success" flat>
              <v-toolbar-title class="white--text title">Notice</v-toolbar-title>
            </v-toolbar>
            <div class="p-3">
              <p class="font-weight-bold body-1 mb-2"> Company for Verification. </p>
              <p class="mb-0 text-grey">
                We now need to verify your company email address.
                We've send an email to <b>  {{ display.email }} </b> to verify your address.
                Please click the link in that email to register your company.
              </p>
            </div>
            <v-card-actions class="border-top">
              <v-spacer> </v-spacer>
              <v-btn text color="#0A009B" @click="dialog.show = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </v-container>
</template>
<script>
import timezone from '@/components/Timezone'
import Countries from '@/components/Countries'

export default {
  props : ['myInfo'],
  components : {
    timezone,
    Countries
  },
  data()
  {
    return {
      arrays : {
        me: null,
        errors: [],
        products : [],
        companies : [],
        countries : [],
      },
      status : {
        me : false,
        admin : false,
        type : 'owner',
        parent : false,
        parent_id : null,
        inviter: false,
      },
      snackbar : {
        show : false
      },
      form: {
        owner : {
          logo: null,
          user_id: null,
          product_id: null,
          company : {
            country : null,
            name : null,
            status : 'active',
            address : null,
            contact_number : null,
            company_email : null,
            timezone_id : null,
          },
          type : 'owner',
          trial_add : 30,
        },
        affiliated : {
          name: null,
          logo: null,
          user_id: null,
          status: "Active",
          country : null,
          address: null,
          contact_number: null,
          company_email: null,
          parent_id: null,
          timezone_id: null,
          date_created: null,
          billing_date : null,
          type : 'affiliated',
          trial_add : 30,
        }
      },
      loadings: {
        btn_create: false,
        overlay : false,
        products : false,
      },
      disabled : {
        owner : false,
        affiliated : false,
        cancel : false
      },
      rules : {
        name: [
          (value) => !!value || "Name is required",
          (value) =>
            (value && value.length <= 50) || "Name must be less than 50 characters",
        ],
        contact : [
          (value) => !!value || 'Contact number is required'
        ],
        email : [
          (value) => !!value || "Email is required",
          (value) => /.+@.+\..+/.test(value) || "E-mail must be valid",
        ],
        address : [
          (value) => !!value || "Address is required"
        ],
        image : [
          (value) =>
            !value || value.size < 2000000 || "Logo size should be less than 2 MB!",
        ],
        product: [
          (value) => !!value || 'Please select your product'
        ],
        parent: [
          (value) => !!value || 'Please select your parent company'
        ],
        country : [
          (value) => !!value || 'Please select country'
        ]
      },
      display: {
        parent_image : null,
        affiliated_image : null,
        name : null,
        email : null
      },
      dialog : {
        show : false,
      }
    }
  },
  created()
  {
    if(this.myInfo)
    {
       this.populate()
    }
    this.products()
  },
  watch : {
    myInfo : function(me)
    {
      if(me)
      {
        this.populate()
      }
    }
  },
  computed :{
    superCompanies()
    {
      return this.arrays.companies.filter( company => {
          return company.role == "SA"
        })
    } 
  },    
  methods : {
    imageUpload(type) {
      let file = event.target.files[0];
      if(file)
      {
        if (file.size > 1048770) {
          console.log("File is to Biiig");
        } else {
          let reader = new FileReader();
          reader.onload = (event) => {
            if(type == 'parent')
            {
              this.display.parent_image = event.target.result
              this.form.owner.logo = event.target.result;
            }else{
            
              this.display.affiliated_image = event.target.result
              this.form.affiliated.logo = event.target.result
            }
          };
          reader.readAsDataURL(file);
        }
      }else{
        this.display.affiliated_image = null
        this.form.affiliated.logo = null
        this.display.parent_image = null
        this.form.owner.logo = null
      }
    },
    uploadLogo(type)
    {
      if(type == 'parent')
      {
        this.$refs.parentUploader.click()
      }else{
        this.$refs.affiliatedUploader.click()
      }
    },
    checkDuplicate()
    {
      let c = null
      let cn = null
      let validate = false
      this.loadings.btn_create
      if(this.status.type == 'owner')
      {
        if(this.$refs.formOwner.validate() )
        {
          validate = true
          c = this.form.owner.company.country
          cn = this.form.owner.company.contact_number
          this.loadings.btn_create = true
        }
      }else{
        if(this.$refs.formAffiliated.validate() )
        {
          validate = true
          c = this.form.affiliated.country
          cn = this.form.affiliated.contact_number
          this.loadings.btn_create = true
        }
      }
      if(validate)
      {
        cn = cn.replace(/\s/g, '')
        let url = '/companies?country=' + c + '&contact_number=' + cn + '&find=true&per_page=1'
        this.axios.get(url)
        .then( ({data}) => {
          if(data.meta && data.meta.total > 0)
          {
            this.arrays.errors.push('The company contact number already exist, please provide another phone number')
            this.loadings.btn_create = false
          }else{
            this.arrays.errors = []
            if(this.status.type == 'owner')
            {
              this.createOwner()
            }else{
              this.createAffilated()
            }
          }
        }) 
      }
    },
    type(type)
    {
      if(this.status.type == 'owner' )
      {
        this.$refs.formOwner.reset()
      }else{
        this.$refs.formAffiliated.reset()
      }
      this.status.type = type
    },
    products()
    {
      this.loadings.products = true
      this.axios.get('/products')
        .then( ({data}) => {
          this.arrays.products = data.products
          this.loadings.products = false
        })
    },
    populate()
    {
      this.arrays.me = this.myInfo
      if(this.myInfo.default_user_company.role == 'SA')
      {
        this.status.admin = true
      }else{
        this.status.admin = false
      }
      if(this.arrays.countries.length <= 0)
      {
         this.arrays.countries = this.$countries.names()
      }
      this.getCompanies()
    },
    accept()
    {
      if(this.status.type == 'owner')
      {
        if(this.form.owner.company.contact_number && this.form.owner.company.contact_number.length > 0)
        {
          let x = this.form.owner.company.contact_number.replace(/\D/g , '').match(/(\d{0,4})(\d{0,3})(\d{0,4})/)
          this.form.owner.company.contact_number = !x[2] ? x[1] :  x[1] + ' ' + x[2] + ( x[3] ? ' ' +  x[3] : '');
        }
      }
      if(this.status.type == 'affiliated')
      {
        if(this.form.affiliated.contact_number && this.form.affiliated.contact_number.length > 0)
        {
          let x = this.form.affiliated.contact_number.replace(/\D/g , '').match(/(\d{0,4})(\d{0,3})(\d{0,4})/)
          this.form.affiliated.contact_number = !x[2] ? x[1] :  x[1] + ' ' + x[2] + ( x[3] ? ' ' +  x[3] : '');
        }
      }
    },
    close()
    {
      this.arrays.errors = []
      this.$router.push({path: '/dashboard'})
      
    },
    select() {
      this.arrays.errors = []
    },
    createOwner()
    {
      if(this.$refs.formOwner.validate() )
      { 
          this.form.owner.user_id = this.arrays.me.id;
          this.display.name = this.form.owner.company.name
          this.display.email = this.form.owner.company.company_email
          this.disabled.owner = true
          if(this.form.owner.contact_number && this.form.owner.contact_number.length > 0)
          {
            this.form.owner.contact_number = this.form.owner.contact_number.replace(/\s/g, '') 
          }


       

          this.loadings.btn_create = true;
          this.disabled.cancel = true;
          this.axios
            .post("sendcompanyverification", this.form.owner)
            .then( () => {
              this.disabled.owner = false
              this.dialog.show = true
              this.$refs.formOwner.reset()
            })
            .catch( error => {
              if(error.response.status == 400)
              {
                this.arrays.errors = []
                let data = error.response.data.errors
                if(data.timezone_id)
                {
                  this.arrays.errors.push('Please provide timezone')
                }
              }
              this.disabled.owner = false
            })
            .finally(() =>  {
              this.loadings.btn_create = false
              this.disabled.cancel = false
              
              
            });
      }
    },
    createAffilated()
    {
     if(this.$refs.formAffiliated.validate() )
      {
        this.form.affiliated.user_id = this.arrays.me.id;
        this.form.affiliated.parent_id = this.arrays.me.default_user_company.company_id;
        this.form.affiliated.billing_date = this.$options.filters.today()

        this.display.name = this.form.affiliated.name
        this.display.email = this.form.affiliated.company_email
        this.disabled.affiliated = true

        if(this.form.affiliated.contact_number && this.form.affiliated.contact_number.length > 0)
        {
           this.form.affiliated.contact_number = this.form.affiliated.contact_number.replace(/\s/g, '') 
        }
        this.form.affiliated.user_company = {
          user_id : this.form.affiliated.user_id,
          company_id: null,
          role : this.arrays.me.default_user_company.role,
          parent_id : this.arrays.me.default_user_company.company_id ,
          status: 'active',
          timezone_id : this.form.affiliated.timezone_id,
          team_dashboard : this.arrays.me.default_user_company.company_id,
        }
        this.loadings.btn_create = true
        this.disabled.cancel = true;
        this.axios
          .post("sendcompanyverification", this.form.affiliated)
          .then(() => {
            this.disabled.affiliated = false
            this.dialog.show = true
            this.$refs.formAffiliated.reset()
            // this.$emit('refresh', true)
            // this.$router.push({path: '/account/mycompanies'})
          })
          .catch( error => {
          
            if(error.response.status == 400)
            {
              this.arrays.errors = []
              let data = error.response.data.errors
              if(data.timezone_id)
              {
                this.arrays.errors.push('Please provide timezone')
              }
            }
          })
          .finally(() => {
            this.loadings.btn_create = false
            this.disabled.cancel = false;
          });
      }
    },
    getCompanies()
    {
       let url = '/getusercombyuserid/' + this.arrays.me.id
       this.axios.get(url)
       .then(  ({data}) => {
         this.arrays.companies = data.companies
       })

    }
  }
}
</script>
<style scoped>
.v-input--selection-controls__ripple{
  font-size: 24px;
}
</style>